

$(document).ready(function () {

    // $.ajaxSetup({
    //     headers: {
    //         "X-CSRFToken": 'aa';
    //     }
    // });

    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf_token"]').attr('content')
      }
    });

   	var form = $('#contactForm');
   	
   	form.on('submit', function(e){
   		e.preventDefault();

   		form.find('span.error').remove();

   		form.find('button[type="submit"]').html('<i class="fa fa-refresh fa-spin"></i> Enviando...').attr('disabled', 'disabled');

   		$.ajax({
   			method: form.attr("method"),
   			url: form.attr("action"),
   			data: form.serialize()
   		})
   		.done(function(data){
   			form.find("input, textarea").val('');
   			swal("Sucesso!", "Obrigado pelo contato, retornaremos em breve.", "success");
   		})
   		.fail(function(data){
   			$.each(data.responseJSON, function(index, el) {
   				form.find('[name="'+index+'"]').parent().append('<span for="'+index+'" class="error">'+el[0]+'</span>');
   			});
   		})
   		.always(function(data){
   			form.find('button[type="submit"]').html('Enviar Mensagem').removeAttr('disabled');
   		});

   	});

});