/*
 * Browser Detection
 * © 2010 DevSlide Labs 
 * 
 * Visit us at: www.devslide.com/labs
 */

var notSupportedBrowsers = [
		{'os': 'Any', 'browser': 'MSIE', 'version': 9}, 
		{'os': 'Any', 'browser': 'Firefox', 'version': 28}, 
		{'os': 'Any', 'browser': 'Chrome', 'version': 32}
	];
var displayPoweredBy = false;
var noticeLang = 'guco';
var noticeLangCustom = null;
var supportedBrowsers = [];

var BrowserDetection = {
	init: function(){

		if(notSupportedBrowsers == null || notSupportedBrowsers.length < 1){
			notSupportedBrowsers = this.defaultNotSupportedBrowsers;
		}
		
		this.detectBrowser();
		this.detectOS();
		
		if(this.browser == '' || this.browser == 'Unknown' || this.os == '' || 
		   this.os == 'Unknown' || this.browserVersion == '' || this.browserVersion == 0)
		{
			return;
		}
		
		// Check if this is old browser
		var oldBrowser = false;
		for(var i = 0; i < notSupportedBrowsers.length; i++){
			if(notSupportedBrowsers[i].os == 'Any' || notSupportedBrowsers[i].os == this.os){
				if(notSupportedBrowsers[i].browser == 'Any' || notSupportedBrowsers[i].browser == this.browser){
					if(notSupportedBrowsers[i].version == "Any" || this.browserVersion <= parseFloat(notSupportedBrowsers[i].version)){
						oldBrowser = true;
						break;
					}
				} 
			}
		}

		if(oldBrowser){
			this.displayNotice();
		}
	},
	
	getEl: function(id){ 
		return window.document.getElementById(id);
	},

	getElSize: function(id){ 
		var el = this.getEl(id); 
		if(el == null){ return null; } 
		return { 'width': parseInt(el.offsetWidth), 'height': parseInt(el.offsetHeight) }; 
	},

	getWindowSize: function(){
		if(typeof window.innerWidth != 'undefined'){
			return {'width': parseInt(window.innerWidth), 'height': parseInt(window.innerHeight)};
		} else {
			if(window.document.documentElement.clientWidth != 0){
				return {'width': parseInt(window.document.documentElement.clientWidth), 'height': parseInt(window.document.documentElement.clientHeight)};
			} else {
				return {'width': parseInt(window.document.body.clientWidth), 'height': parseInt(window.document.body.clientHeight)};
			}
		}
	},

	positionNotice: function(){
		var noticeSize = this.getElSize('browser-detection');
		var windowSize = this.getWindowSize();
		var noticeEl = this.getEl('browser-detection');
		
		if(noticeEl == null || noticeSize == null || windowSize == null || !windowSize.width || !windowSize.height){ return; }
		noticeEl.style.left = (windowSize.width - noticeSize.width) / 2 + "px";
		
		var offset = (this.browser == "MSIE" && this.browserVersion < 7) ? (window.document.documentElement.scrollTop != 0 ? window.document.documentElement.scrollTop : window.document.body.scrollTop) : 0;
		noticeEl.style.top = (windowSize.height - noticeSize.height) / 2 + "px";
		this.noticeHeight = noticeSize.height;
	},
	
	displayNotice: function(){
		if(this.readCookie('bdnotice') == 1){
			return;
		}
		
		this.writeNoticeCode();
		this.positionNotice();
		
		var el = this;
		window.onresize = function(){ el.positionNotice(); };
		if(this.browser == "MSIE" && this.browserVersion < 7){
			window.onscroll = function(){ el.positionNotice(); };
		}
		
		this.getEl('browser-detection-remind-later').onclick = function(){ el.remindMe(false); };
		// this.getEl('browser-detection-never-remind').onclick = function(){ el.remindMe(true); };
	},
	
	remindMe: function(never){
		this.writeCookie('bdnotice', 1, never == true ? 365 : 1);
		this.getEl('browser-detection').style.display = 'none';
		this.getEl('black_overlay').style.display = 'none';
	},
	
	writeCookie: function(name, value, days){
		var expiration = ""; 
		if(parseInt(days) !== 0){
			var date = new Date();
			date.setTime(date.getTime() + parseInt(days) * 24 * 60 * 60 * 1000);
			expiration = '; expires=' + date.toGMTString();
		}

		document.cookie = name + '=' + value + expiration + '; path=/';
	},
	
	readCookie: function(name){
		if(!document.cookie){ return ''; }
		
		var searchName = name + '='; 
		var data = document.cookie.split(';');
		
		for(var i = 0; i < data.length; i++){
			while(data[i].charAt(0) == ' '){
				data[i] = data[i].substring(1, data[i].length);
			}
			
			if(data[i].indexOf(searchName) == 0){ 
				return data[i].substring(searchName.length, data[i].length);
			}
		}
		
		return '';
	},
	
	writeNoticeCode: function(){
		var title = '';
		var notice = '';
		var selectBrowser = '';
		var remindMeLater = '';
		var neverRemindAgain = '';
		
		var browsersList = null;		
		var code = '<div id="black_overlay"></div><div id="browser-detection">';
		
		if(noticeLang == 'custom' && noticeLangCustom != null){
			title = noticeLangCustom.title;
			notice = noticeLangCustom.notice;
			selectBrowser = noticeLangCustom.selectBrowser;
			remindMeLater = noticeLangCustom.remindMeLater;
			neverRemindAgain = noticeLangCustom.neverRemindAgain;
		} else {
			var noticeTextObj = null;
			eval('noticeTextObj = this.noticeText.' + noticeLang + ';');
			
			if(!noticeTextObj){
				noticeTextObj = this.noticeText.professional;
			}
			
			title = noticeTextObj.title;
			notice = noticeTextObj.notice;
			selectBrowser = noticeTextObj.selectBrowser;
			remindMeLater = noticeTextObj.remindMeLater;
			neverRemindAgain = noticeTextObj.neverRemindAgain;
		}
				
		code += '<p class="bd-title">' + title + '</p><p class="bd-notice2">' + selectBrowser + '</p>';
		
		if(supportedBrowsers.length > 0){
			browsersList = supportedBrowsers;
		} else {
			browsersList = this.supportedBrowsers;
		}

		code += '<div class="bd-browsers-list">';
		if (this.browser == 'Chrome') {
			code += '<a href="' + browsersList[1].downloadUrl + '" class="' + browsersList[1].cssClass + '" target="_blank">' + browsersList[1].name + '</a>';
		} else if(this.browser == 'Opera') {
			code += '<a href="' + browsersList[3].downloadUrl + '" class="' + browsersList[3].cssClass + '" target="_blank">' + browsersList[3].name + '</a>';
		} else if(this.browser == 'MSIE') {
			code += '<a href="' + browsersList[2].downloadUrl + '" class="' + browsersList[2].cssClass + '" target="_blank">' + browsersList[2].name + '</a>';
		} else if(this.browser == 'Safari') {
			code += '<a href="' + browsersList[4].downloadUrl + '" class="' + browsersList[4].cssClass + '" target="_blank">' + browsersList[4].name + '</a>';
		} else if(this.browser == 'Firefox') {
			code += '<a href="' + browsersList[0].downloadUrl + '" class="' + browsersList[0].cssClass + '" target="_blank">' + browsersList[0].name + '</a>';
		} else {
			for(var i = 0; i < browsersList.length; i++){
				code += '<a href="' + browsersList[i].downloadUrl + '" class="' + browsersList[i].cssClass + '" target="_blank">' + browsersList[i].name + '</a>';
			}
		}
					
		code += '</div>';
		
		code += '<div class="bd-skip-buttons">';
		code += '<a id="browser-detection-remind-later">' + remindMeLater + '</a>';
		// code += '<a id="browser-detection-never-remind">' + neverRemindAgain + '</a>';
		code += '</div>';
		code += '<p class="bd-notice">' + notice + '</p>';
		code += '</div>';
		window.document.body.innerHTML += code;
	},

	detectBrowser: function(){
		this.browser = '';
		this.browserVersion = 0;
		
		if(/Opera[\/\s](\d+\.\d+)/.test(navigator.userAgent)){
			this.browser = 'Opera';
		} else if(/MSIE (\d+\.\d+);/.test(navigator.userAgent)){
			this.browser = 'MSIE';
		} else if(/Navigator[\/\s](\d+\.\d+)/.test(navigator.userAgent)){
			this.browser = 'Netscape';
		} else if(/Chrome[\/\s](\d+\.\d+)/.test(navigator.userAgent)){
			this.browser = 'Chrome';
		} else if(/Safari[\/\s](\d+\.\d+)/.test(navigator.userAgent)){
			this.browser = 'Safari';
			/Version[\/\s](\d+\.\d+)/.test(navigator.userAgent);
			this.browserVersion = new Number(RegExp.$1);
		} else if(/Firefox[\/\s](\d+\.\d+)/.test(navigator.userAgent)){
			this.browser = 'Firefox';
		}
		
		if(this.browser == ''){
			this.browser = 'Unknown';
		} else if(this.browserVersion == 0) {
			this.browserVersion = parseFloat(new Number(RegExp.$1));
		}		
	},
	
	// Detect operation system
	detectOS: function(){
		for(var i = 0; i < this.operatingSystems.length; i++){
			if(this.operatingSystems[i].searchString.indexOf(this.operatingSystems[i].subStr) != -1){
				this.os = this.operatingSystems[i].name;
				return;
			}
		}
		
		this.os = "Unknown";
	},
	
	//	Variables
	noticeHeight: 0,
	browser: '',
	os: '',
	browserVersion: '',
	supportedBrowsers: [
		{ 'cssClass': 'firefox', 'name': 'Mozilla Firefox', 'downloadUrl': 'http://www.mozilla.com/firefox/' },
		{ 'cssClass': 'chrome', 'name': 'Google Chrome', 'downloadUrl': 'http://www.google.com/chrome/' },
		{ 'cssClass': 'msie', 'name': 'Internet Explorer', 'downloadUrl': 'http://www.microsoft.com/windows/Internet-explorer/' },
		{ 'cssClass': 'opera', 'name': 'Opera', 'downloadUrl': 'http://www.opera.com/download/' },
		{ 'cssClass': 'safari', 'name': 'Apple Safari', 'downloadUrl': 'http://www.apple.com/safari/download/' }
	],
	operatingSystems: [
		{ 'searchString': navigator.platform, 'name': 'Windows', 'subStr': 'Win' },
		{ 'searchString': navigator.platform, 'name': 'Mac', 'subStr': 'Mac' },
		{ 'searchString': navigator.platform, 'name': 'Linux', 'subStr': 'Linux' },
		{ 'searchString': navigator.userAgent, 'name': 'iPhone', 'subStr': 'iPhone/iPod' }
	],
	defaultNotSupportedBrowsers: [{'os': 'Any', 'browser': 'MSIE', 'version': 6}],
	noticeText: {
		'guco': { 
			"title": "Seu navegador está desatualizado e talvez não seja compatível com este website.", 
			"notice": "Nós recomendamos o uso do navegador <a href='http://www.google.com/chrome/' target=''><strong>Google Chrome</strong></a>.<br>Fechando essa mensagem, o seu navegador não será atualizado e a sua experiência no website poderá não ser a ideal.",
			"selectBrowser": "Clique abaixo para atualizá-lo",
			"remindMeLater": "Fechar esta janela",
			"neverRemindAgain": "Não mostre essa mensagem novamente!" 
		},
		'informal': { "title": "Whoaaa!", "notice": "It appears you're using an outdated browser which prevents access to some of the features on our website. While it's not required, you really should <b>upgrade or install a new browser</b>!", "selectBrowser": "Visit the official sites for popular browsers below:", "remindMeLater": "Not now, but maybe later", "neverRemindAgain": "No, don't remind me again" },
		'technical': { "title": "Old Browser Alert! <span class='bd-highlight'>DEFCON 5</span>", "notice": "Come on! If you are hitting our site, then you must at least be partially tech savvy. So, why the older browser? We're not asking you to brush off your old Fibonacci Heap and share it with the class. Just upgrade!\nI know, I know. You don't like to be told what to do. But, we're only asking you to upgrade so you can access all the latest, greatest features on our site. It's quick and easy. But, if you still want to skip it, that's cool. We will still welcome you &mdash; and your creepy old browser. :P", "selectBrowser": "Visit the official sites for popular browsers below:", "remindMeLater": "Remind me later", "neverRemindAgain": "No, don't remind me. I like my Commodore 64!" },
		'goofy': { "title": "Are You Serious?", "notice": "Are you really using <b>{browser_name}</b> as your browser?\nYou're surfing the web on a dinosaur (a dangerous one too &mdash; like a Tyrannosaurus or Pterodactyl or something scary like that). <b>Get with it and upgrade now!</b> If you do, we promise you will enjoy our site a whole lot more. :)", "selectBrowser": "Visit the official sites for popular browsers below:", "remindMeLater": "Maybe Later", "neverRemindAgain": "No, don't remind me again" },
		'mean': { "title": "Umm, Your Browser Sucks!", "notice": "Get a new one here.", "selectBrowser": "Official sites for popular browsers:", "remindMeLater": "Remind me later, a'hole", "neverRemindAgain": "F' off! My browser rocks!" }
	}
};

window.onload = function(){
	BrowserDetection.init();
};